import { useUserTracked } from "presentation/store/User/UserProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import UserGroupFormPanel from "./Form/UserGroupFormPanel";
import UserGroupTablePanel from "./Table/UserGroupTablePanel";

const UserGroupMaintenance:React.FC = () => {
    const [ userState ] = useUserTracked();
    const { isSliderOpen } = userState.userGroupState

    return <div className='main-comp-wrapper'>
            <SliderPanel
                isOpen={isSliderOpen}
                leftSectionWidth={'100%'}
                rightSectionWidth={'60%'}
                draggable={true}
                leftChildren={<UserGroupTablePanel/>}
                rightChildren={<UserGroupFormPanel/>} />
        </div>
}

export default memo(UserGroupMaintenance);