import { useUserTracked } from "presentation/store/User/UserProvider";
import { memo } from "react"
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import UserGroupMaintenance from "./UserGroupMaintenance";
import UserTablePanel from "./Table/UserTablePanel";
import UserFormPanel from "./Form/UserFormPanel";

const UserComp:React.FC = () => {
    const [ userState ] = useUserTracked();
    const { isAdd, isEditable, isSliderOpen } = userState.userState;

    return <>
        <div className="main-comp-wrapper">
            {/* User table panel */}
            <UserTablePanel/>
            {/* User editable panel */}
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"70%"}
                    leftChildren={<UserFormPanel/>}
                    rightChildren={<UserGroupMaintenance/>}
                />
                )
            }
        </div>
    </>
}

export default memo(UserComp);