import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarCaption, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UserGroupForm from "./UserGroupForm";

const UserGroupFormPanel : React.FC = () => {
    const [ userState ] = useUserTracked();
    const currentUserGroup = userState.userGroupState.currentSelectedData;
    const { isAdd, isEditable, isRead } = userState.userGroupState;
    const userVM = useUserVM();
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;
    const [, setIsSave] = useState(false);
    const [ anaInfoSate ] = useANAInfoTracked();

    const closeSlider = useCallback(() => {
        userVM.onGroupCloseClick();
    }, [userVM])

    const saveButtonClicked = useCallback(async() => {
        setIsSave(true);        
        userVM.onGroupSaveClicked();
        await userVM.onGroupSave(currentUserGroup, isAdd);
    }, [userVM, isAdd, currentUserGroup]);

    return (
        <div className='side-form-content-wrapper' style={{position:'relative', height:"100vh-128px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {/* If user add new record */}
                            {isAdd && <SidebarTitle>{USERGROUP_CONSTANT.ADDGROUP}</SidebarTitle>}

                            {/* If user edit existing record */}
                            {isEditable && <SidebarTitle>{currentUserGroup.name}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>{"All fields are required unless indicated as 'optional'"}</SidebarCaption>}
                        </SidebarTitle>
                        <SidebarActionCross>
                            {isRead && <IconButton
                             fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeSlider} />}
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>
            {/* Stack Form Start */}
            <UserGroupForm/>
            <div>
                <SidebarActionBar>
                    {!isRead && <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={closeSlider} />}
                    {!isRead && (anaInfoSate.allowCreate || anaInfoSate.allowUpdate) && <HPHButton label={isAdd ? 'Add' : 'Update'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />}
                </SidebarActionBar>
            </div>
        </div>
    );
}

export default UserGroupFormPanel;