import { UserConStant } from "presentation/constant/User/UserConstant";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { useMemo } from "react";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UserTitleBar from "../UserTitleBar";
import UserForm from "./UserForm";

const UserFormPanel : React.FC = () => {
    const [ userState ] = useUserTracked();
    const { isAdd, isEditable, isRead } = userState.userState;
    const currentUser = userState.userState.currentSelectedData;
    const USER_CONSTANT = UserConStant.user;

    const memoUserTitleBar = useMemo(() => {
        return <UserTitleBar/>
    }, [])

    const memoUserForm = useMemo(() => {
        return <UserForm/>
    }, [])

    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoUserTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                {/* If user add new record */}
                                {isAdd && <SidebarTitle>{USER_CONSTANT.ADDUSER}</SidebarTitle>}

                                {/* If user edit existing record */}
                                {isEditable && <SidebarTitle>{currentUser.name}</SidebarTitle>}

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>

                        {/* General Information */}
                        {memoUserForm}
                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}

export default UserFormPanel;